import type { IShoppingCartGet } from "shared"

export type IShoppingCartPriceInfo = {
  totalPrice: number,
  totalDiscount: number,
  discountAppliedTotalPrice: number,
  discountPercent: number,
}

export const calcShoppingCartPriceInfo = (
  shoppingCart: IShoppingCartGet
): IShoppingCartPriceInfo => {
  let totalPrice = 0
  let totalDiscount = 0

  shoppingCart.items
    .map(item => item.product.price * item.quantity)
    .forEach(item => totalPrice += item)

  shoppingCart.items
    .map(item => (item.product.discount / 100 * item.product.price) * item.quantity)
    .filter(item => item != undefined)
    .forEach(item => totalDiscount += item)

  const discountAppliedTotalPrice = totalPrice - totalDiscount
  const discountPercent = roundToX(totalDiscount / totalPrice * 100, 1)

  return {
    totalPrice,
    totalDiscount,
    discountPercent,
    discountAppliedTotalPrice
  }
}

export const priceFormat = (price: number) =>
  price.toLocaleString('en-US', { maximumFractionDigits: 0 })


export const roundToX = (num: number, decimal = 2) => {
  let p = Math.pow(10, decimal)
  return Math.round(num * p) / p
}
